import React from "react";
import { SvgIcon } from "@mui/material";
import { mdiAccessPoint } from "@mdi/js";
import { routes as r } from "../routes";
import { getTranslation } from "orion-components/i18n";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import deepDiff from "deep-diff";
import { mapRecords, filterDeletedRecords } from "./changeRequestMappings";
import SpriteIcon from "orion-components/SharedComponents/SpriteIcon/SpriteIcon";

export const groupOrder = ["organization", "ecosystem", "user"];

export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFullName = (firstName, lastName) => {
	return capitalize(firstName) + " " + capitalize(lastName);
};

export const formatPhone = (value) => {
	if (!value) return "";

	let input = value;
	// Strip all characters from the input except digits
	input = input.replace(/\D/g, "");

	// Trim the remaining input to ten characters, to preserve phone number format
	input = input.substring(0, 10);

	// Based upon the length of the string, we add formatting as necessary
	const size = input.length;
	if (size === 0) {
		input = "";
	} else if (size < 4) {
		input = "(" + input;
	} else if (size < 7) {
		input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
	} else {
		input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + " - " + input.substring(6, 10);
	}
	return input;
};

export const formatDate = (date) => {
	const newDate = new Date(date);
	return newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear();
};

export const getFirstName = (fullName) => {
	return fullName.split(" ")[0];
};

export const getLastName = (fullName) => {
	return fullName.split(" ")[1];
};

export const transformRole = (role) => {
	return role
		.split("-")
		.map((word) => word[0].toUpperCase() + word.substr(1))
		.join(" ");
};

export const getSafely = (fn) => {
	try {
		return fn();
	} catch (err) {
		console.log("Avoided a null reference, returning undefined instead:");
		console.log(err);
		return undefined;
	}
};

// Use this with .sort to alphabetically sort an array of objects by object property
// dogs.sort(dynamicSort("breeds"))   --->   Dog is an array of objects, sorts alphabetically by the "breeds" property
// dogs.sort(dynamicSort("-breeds"))  --->   Dog is an array of objects, sorts reverse alphabetically by the "breeds" property
export const dynamicSort = (property) => {
	let sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		const result =
			a[property].replace(/\s/g, "").toLowerCase() < b[property].replace(/\s/g, "").toLowerCase()
				? -1
				: a[property].replace(/\s/g, "").toLowerCase() > b[property].replace(/\s/g, "").toLowerCase()
				? 1
				: 0;
		return result * sortOrder;
	};
};

export const getIntegrationIcon = (type, fontSize = "2rem") => {
	let icon;
	let materialUI = false;
	let mdiIcon = false;
	switch (type) {
		case "Shapes":
			icon = "../static/icons/shapes-white.svg";
			break;
		case "Track":
			icon = "../static/icons/vessel-white-01.svg";
			break;
		case "Vehicle":
			materialUI = true;
			icon = "directions_car";
			break;
		case "Camera":
			icon = "../static/icons/camera.svg";
			break;
		case "Person":
			icon = "../static/icons/person.svg";
			break;
		case "device":
			mdiIcon = true;
			icon = mdiAccessPoint;
			break;
		default:
			icon = null;
			break;
	}
	if (!icon) {
		return false;
	}
	if (materialUI) {
		return (
			<i className="material-icons" style={{ fontSize, color: "#B5B9BE" }}>
				{icon}
			</i>
		);
	} else if (mdiIcon) {
		return (
			<SvgIcon style={{ width: 34, height: 34, color: "#fff" }}>
				<path d={icon} />
			</SvgIcon>
		);
	} else {
		return (
			<div
				className="int-icon"
				style={{
					fontSize,
					color: "#B5B9BE",
					backgroundImage: `url(${icon})`
				}}
			></div>
		);
	}
};

export const getNavigationProps = (pathname) => {
	const nav = {};
	const handleDynamicRoutes = () => {
		if (pathname.includes("user/")) {
			nav.path = getTranslation("sideBar.navPath.userAccount");
			nav.order = 2;
			nav.parentPath = r.MANAGE_USERS;
			nav.doneEditing = true;
			nav.modal = "account_settings";
			nav.validation = true;
		} else if (pathname.includes("manage-ecosystem/")) {
			nav.path = getTranslation("sideBar.option3");
			nav.order = 3;
			nav.parentPath = r.MANAGE_ECOSYSTEM;
			nav.modal = "manage_ecosystem";
			nav.doneEditing = true;
		} else if (pathname.includes("manage-feed-sharing-policies/")) {
			nav.path = getTranslation("sideBar.navPath.feedSharingPolicies");
			nav.order = 2;
			nav.parentPath = r.MANAGE_FEED_SHARING_POLICIES;
			nav.modal = "feed_sharing_policies";
			nav.doneEditing = true;
		} else if (pathname.includes("manage-organization/")) {
			nav.path = getTranslation("sideBar.option2");
			nav.order = 2;
			nav.modal = "manage_organization";
			nav.doneEditing = true;
		}
	};

	switch (pathname) {
		case r.MY_ACCOUNT: {
			nav.path = getTranslation("sideBar.option1.variant1");
			nav.order = 1;
			nav.modal = "account_settings";
			nav.validation = true;
			break;
		}
		case r.MANAGE_ORGANIZATION:
		case r.MY_ORGANIZATION: {
			nav.path = getTranslation("sideBar.option2");
			nav.order = 2;
			nav.modal = "manage_organization";
			nav.validation = true;
			break;
		}
		case r.MANAGE_ECOSYSTEM: {
			nav.path = getTranslation("sideBar.option3");
			nav.order = 3;
			nav.modal = "manage_ecosystem";
			break;
		}
		case r.ACTIVE_DIRECTORY: {
			nav.path = getTranslation("sideBar.navPath.activeDirectory");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "active_directory";
			break;
		}
		case r.MANAGE_USER_ROLES: {
			nav.path = getTranslation("sideBar.navPath.userRoles");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "user_roles";
			break;
		}
		case r.MANAGE_USERS: {
			nav.path = getTranslation("sideBar.navPath.users");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "users";
			break;
		}
		case r.MANAGE_LABELS: {
			nav.path = getTranslation("sideBar.navPath.labels");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "labels";
			nav.validation = true;
			break;
		}
		case r.SHARING_CONNECTIONS: {
			nav.path = getTranslation("sideBar.navPath.sharingConn");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "sharing_connections";
			nav.validation = true;
			break;
		}
		case r.MANAGE_FEED_SHARING_POLICIES: {
			nav.path = getTranslation("sideBar.navPath.feedSharingPolicies");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "feed_sharing_policies";
			break;
		}
		case r.CHANGE_REQUEST_REVIEW: {
			nav.path = getTranslation("sideBar.option1.variant4");
			nav.order = 4;
			nav.modal = "change_request_review";
			break;
		}
		case r.CHANGE_REQUEST_REQUESTED: {
			nav.path = getTranslation("sideBar.requested");
			nav.order = 4;
			nav.modal = "change_request_requested";
			break;
		}
		case r.MANAGE_EVENT_TYPES_PRIORITIES: {
			nav.path = getTranslation("sideBar.navPath.manageEvents");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "manage_event_types_priorities";
			break;
		}
		case r.SECONDARY_FAILOVER: {
			nav.path = getTranslation("sideBar.navPath.secondaryFailover");
			nav.order = 2;
			nav.parentPath = r.MANAGE_ORGANIZATION;
			nav.modal = "secondary_failover";
			break;
		}
		default:
			handleDynamicRoutes();
			break;
	}
	return nav;
};

export const validateEmail = (value) => {
	let valid = true;

	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	valid = re.test(value);

	if (value.length === 0) {
		valid = false;
	}

	return valid;
};

export const phoneValidate = (value) => {
	let valid = true;

	// 16 is the length of a fully formatted 10-digit phone number
	if (value.length > 0 && value.length < 16) {
		valid = false;
	}
	return valid;
};

export const getSideBarNavItems = (isAdmin) => {
	return [
		{
			name: getTranslation("sideBar.option1.variant1"),
			to: r.MY_ACCOUNT,
			order: 1,
			subSection: [
				{
					id: "profile",
					name: getTranslation("sideBar.profile"),
					to: r.MY_ACCOUNT
				},
				{
					id: "settings",
					name: getTranslation("sideBar.settings"),
					to: r.MY_ACCOUNT
				}
			]
		},
		{
			name: getTranslation("sideBar.option2"),
			to: r.MANAGE_ORGANIZATION,
			order: 2,
			subSection: [
				{
					id: "org_profile",
					name: getTranslation("sideBar.orgProfile"),
					to: r.MANAGE_ORGANIZATION
				},
				{
					id: "org_settings",
					name: getTranslation("sideBar.orgSettings"),
					to: r.MANAGE_ORGANIZATION,
					subMenu: [
						{
							id: "active_directory",
							name: getTranslation("sideBar.activeDirectory"),
							to: r.ACTIVE_DIRECTORY
						},
						{
							id: "manage_user_roles",
							name: getTranslation("sideBar.userRoles"),
							to: r.MANAGE_USER_ROLES
						},
						{
							id: "manage_users",
							name: getTranslation("sideBar.users"),
							to: r.MANAGE_USERS
						},
						{
							id: "manage_labels",
							name: getTranslation("sideBar.labels"),
							to: r.MANAGE_LABELS
						},
						{
							id: "sharing_connections",
							name: getTranslation("sideBar.sharingConnections"),
							to: r.SHARING_CONNECTIONS
						},
						{
							id: "manage_feed_sharing_policies",
							name: getTranslation("sideBar.manageFeed"),
							to: r.MANAGE_FEED_SHARING_POLICIES
						},
						{
							id: "manage_event_types_priorities",
							name: getTranslation("sideBar.manageEvents"),
							to: r.MANAGE_EVENT_TYPES_PRIORITIES
						},
						{
							id: "secondary_failover",
							name: getTranslation("sideBar.secondaryFailover"),
							to: r.SECONDARY_FAILOVER
						}
					]
				}
			]
		},
		{
			name: getTranslation("sideBar.option3"),
			to: r.MANAGE_ECOSYSTEM,
			order: 3
		},
		{
			name: getTranslation("sideBar.option4"),
			to: isAdmin ? r.CHANGE_REQUEST_REVIEW : r.CHANGE_REQUEST_REQUESTED,
			order: 4,
			subSection: [
				isAdmin && {
					id: "change_request_review",
					name: getTranslation("sideBar.review"),
					to: r.CHANGE_REQUEST_REVIEW
				},
				{
					id: "change_request_requested",
					name: getTranslation("sideBar.requested"),
					to: r.CHANGE_REQUEST_REQUESTED
				}
			].filter(Boolean)
		}
	];
};

export const compareChanges = (
	prevValue,
	changes,
	level,
	changeType,
	integrations,
	applications,
	orgs,
	orgRoles,
	locale
) => {
	const { current, requested } = filterDeletedRecords(prevValue, changes, changeType);

	const differences = deepDiff(current, requested);
	const data = [];
	differences?.map((diff) => {
		data.push(
			mapRecords(
				diff,
				current,
				requested,
				level,
				changeType,
				prevValue,
				integrations,
				applications,
				orgs,
				orgRoles,
				locale
			)
		);
	});

	return data;
};

export const countryCodes = [
	{
		country: "ar",
		code: "+966"
	},
	{
		country: "us",
		code: "+1"
	},
	{
		country: "in",
		code: "+91"
	}
];

export const getChanges = (arrayA, arrayB) => {
	const result = differenceWith(arrayA, arrayB, (a, b) =>
		isEqual(omit(a, "lastModifiedDate"), omit(b, "lastModifiedDate"))
	);

	const filtered = result?.map((obj) => omit(obj, "passwordChangedDate"));
	return filtered;
};

export const checkModified = (arrayA, arrayB) => {
	const result = getChanges(arrayA, arrayB);

	return !isEmpty(result);
};

export const getChangeType = (changeType) => {
	switch (changeType) {
		case "updateAccountSettings":
			return {
				id: "updateAccountSettings",
				route: "/users/bulkSave/accountSettings",
				method: "POST",
				appId: "settings-app",
				level: "user"
			};
		case "updateOrganizationProfile":
			return {
				id: "updateOrganizationProfile",
				route: "/organizations/bulkSave/orgProfile",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateActiveDirectory":
			return {
				id: "updateActiveDirectory",
				route: "/externalAuthProviders/bulkSave",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateUserRoles":
			return {
				id: "updateUserRoles",
				route: "/roles/bulkSave",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateUsers":
			return {
				id: "updateUsers",
				route: "/users/bulkSave",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateLabels":
			return {
				id: "updateLabels",
				route: "/organizations/bulkSave/labels",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateSharingConnections":
			return {
				id: "updateSharingConnections",
				route: "/organizations/bulkSave/sharingConnection",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateFeedSharingPolicies":
			return {
				id: "updateFeedSharingPolicies",
				route: "/organizations/bulkSave/integrations",
				method: "PUT",
				appId: "settings-app",
				level: "organization"
			};
		case "updateSecondaryFailover":
			return {
				id: "updateSecondaryFailover",
				route: "/roles/failover/bulkSave",
				method: "POST",
				appId: "settings-app",
				level: "organization"
			};
		case "updateEventLookups":
			return {
				id: "updateEventLookups",
				route: "/eventLookups/bulkSave",
				method: "PUT",
				appId: "settings-app",
				level: "organization"
			};
		case "updateEcosystem":
			return {
				id: "updateEcosystem",
				route: "/organizations/bulkSave/ecoOrganizations",
				method: "PUT",
				appId: "settings-app",
				level: "ecosystem"
			};
		default:
			return;
	}
};

export const validateStagedFiles = (arrayA, arrayB) => {
	const requested = [...arrayA];
	const current = [...arrayB];

	const newUploads = requested.filter((item) => item.handle);

	newUploads.map((item) => {
		current.map((req) => {
			if (item.id === req.id) {
				req["handle"] = req.profileImage || null;
			}
			return req;
		});
	});
	return { current, requested };
};
export const getFeedIcons = (iconName) => {
	return <SpriteIcon iconName={iconName} customWidth="28px" />;
};
